// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-adminfaq-mdx": () => import("../../src/adminfaq.mdx" /* webpackChunkName: "component---src-adminfaq-mdx" */),
  "component---src-adminguide-mdx": () => import("../../src/adminguide.mdx" /* webpackChunkName: "component---src-adminguide-mdx" */),
  "component---src-basicusage-mdx": () => import("../../src/basicusage.mdx" /* webpackChunkName: "component---src-basicusage-mdx" */),
  "component---src-eula-mdx": () => import("../../src/eula.mdx" /* webpackChunkName: "component---src-eula-mdx" */),
  "component---src-index-mdx": () => import("../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-journeymapping-example-mdx": () => import("../../src/journeymapping-example.mdx" /* webpackChunkName: "component---src-journeymapping-example-mdx" */),
  "component---src-journeymapping-mdx": () => import("../../src/journeymapping.mdx" /* webpackChunkName: "component---src-journeymapping-mdx" */),
  "component---src-privacy-mdx": () => import("../../src/privacy.mdx" /* webpackChunkName: "component---src-privacy-mdx" */),
  "component---src-readconfirmations-example-mdx": () => import("../../src/readconfirmations-example.mdx" /* webpackChunkName: "component---src-readconfirmations-example-mdx" */),
  "component---src-readconfirmations-macro-mdx": () => import("../../src/readconfirmations-macro.mdx" /* webpackChunkName: "component---src-readconfirmations-macro-mdx" */),
  "component---src-readconfirmations-mdx": () => import("../../src/readconfirmations.mdx" /* webpackChunkName: "component---src-readconfirmations-mdx" */),
  "component---src-readconfirmationsadmin-mdx": () => import("../../src/readconfirmationsadmin.mdx" /* webpackChunkName: "component---src-readconfirmationsadmin-mdx" */),
  "component---src-readconfirmations-space-mdx": () => import("../../src/readconfirmations-space.mdx" /* webpackChunkName: "component---src-readconfirmations-space-mdx" */),
  "component---src-userguide-mdx": () => import("../../src/userguide.mdx" /* webpackChunkName: "component---src-userguide-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

