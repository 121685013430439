module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"mode":"dark","fontSizes":[10,12,14,16,20,24,36,48,64],"lineHeights":{"body":1.5,"heading":1.125},"fonts":{"body":"Source Sans Pro, sans-serif","heading":"Source Sans Pro, sans-serif"},"styles":{"p":{"fontSize":[2,3]}}},"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Home",{"name":"Enhanced Sharing for Confluence","menu":["Usage Guide","Admin Guide"]},{"name":"Personal Dashboards for Confluence","menu":["Introduction","Admin FAQ"]},{"name":"Read Confirmations for Confluence","menu":["Introduction","Macro Documentation","Admin Guide"]},{"name":"Journey Mapping for Jira","menu":["Introduction"]}],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":false,"o":false,"open":false,"open-browser":false,"root":"/opt/build/repo/.docz","base":"/","source":"./","src":"./","files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Realigned Docs V2","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/opt/build/repo","templates":"/opt/build/repo/node_modules/docz-core/dist/templates","docz":"/opt/build/repo/.docz","cache":"/opt/build/repo/.docz/.cache","app":"/opt/build/repo/.docz/app","appPackageJson":"/opt/build/repo/package.json","gatsbyConfig":"/opt/build/repo/gatsby-config.js","gatsbyBrowser":"/opt/build/repo/gatsby-browser.js","gatsbyNode":"/opt/build/repo/gatsby-node.js","gatsbySSR":"/opt/build/repo/gatsby-ssr.js","importsJs":"/opt/build/repo/.docz/app/imports.js","rootJs":"/opt/build/repo/.docz/app/root.jsx","indexJs":"/opt/build/repo/.docz/app/index.jsx","indexHtml":"/opt/build/repo/.docz/app/index.html","db":"/opt/build/repo/.docz/app/db.json"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
